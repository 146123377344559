ul {
  margin-bottom: 24px;
}

ul ul li {
  list-style: none;
}

ul ul {
  margin-left: 12px;
}

.MuiBox-root > ul > li {
  list-style: none;
  margin-bottom: 4px;
}
